import React from "react"
import Seo from "../components/molecules/Seo"
import Container from "../components/atoms/Container"
import Header from "../components/molecules/Header";

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <Header title="404 Not Found" topColor="yellow" bottomColor="purple"/>
    <Container>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Container>
  </>
)

export default NotFoundPage
